import React, { useState, useEffect, memo } from 'react'
import { chakra, Box, keyframes } from '@chakra-ui/react'
import { useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FeatureItemFullWidth } from '../../../molecules/FeatureItem'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import redirectToNextIfWindow from '../../../../utils/redirectToNextIfWindow'

const calculateCountry = (profileCode, locationCode) => {
	if (profileCode) {
		return profileCode
	} else if (locationCode) {
		return locationCode
	} else {
		return 'US'
	}
}

const useDeterminedCountry = () => {
	const profile_countryCode = useSelector(
		(s) => (s.auth.profile && s.auth.profile.countryCode ? s.auth.profile.countryCode : null),
		shallowEqual
	)

	const location_countryCode = useSelector(
		(s) => (s.auth.profile && s.auth.profile.location ? s.auth.profile.location.country_code : null),
		shallowEqual
	)

	const [countryCode, setCountry] = useState(calculateCountry(profile_countryCode, location_countryCode))

	useEffect(() => {
		/** Only update if we don't already have a value */
		if (!countryCode) {
			setCountry(calculateCountry(profile_countryCode, location_countryCode))
		}
	}, [profile_countryCode, location_countryCode])

	return countryCode
}

const addonsData = {
	path: '/assets/images/widgets/vidday-video-add-ons-marketplace',
	responsive: true,
	content: () => (
		<chakra.span fontSize="18px" color="white">
			<chakra.strong d="inline" pointerEvents="none">
				Explore creative add-ons
			</chakra.strong>{' '}
			and take your video gift to the next level.
		</chakra.span>
	),
	buttonText: 'Explore Add-ons',
	target: null,
	onClick: () => {},
}

const memmoData = {
	path: '/assets/images/widgets/callout-memmo',
	content: () => (
		<>
			<chakra.strong display="block" pointerEvents="none">
				Get a personalized celebrity video message
			</chakra.strong>{' '}
			with Memmo to include in your VidDay video.
		</>
	),
	target: 'https://tidd.ly/3HFYRrT',
	onClick: () => {},
}

const thrillzData = {
	path: '/assets/images/widgets/vidday-video-addons-thrillz',
	content: () => (
		<>
			<chakra.strong display="block" mb="0.5rem">
				Browse Celebrities
			</chakra.strong>
			Book a celebrity shout-out to include in your video with Thrillz.
		</>
	),
	target: 'http://shrsl.com/3e3za',
	onClick: () => {},
}

const areEqual = (prevProps, nextProps) => {
	/**
	 * If we have a previous and next event,
	 * If id is the same for both event,
	 * If the modified date is identical (Warning: because the date will differ on a sort, this will cause re-render)
	 */
	if (
		prevProps.event &&
		nextProps.event &&
		prevProps.event.id === nextProps.event.id &&
		prevProps.event.updatedAt === nextProps.event.updatedAt
	) {
		return true
	} else return false
}

const Addons = memo(({ event }) => {
	const countryCode = useDeterminedCountry()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()

	/**
	 * Build redirect links when clicked
	 */
	const handleMemmoClick = () =>
		window.open(
			'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref5=app-event&ued=https%3A%2F%2Fmemmo.me%2F%3Futm_source%3Dawin%26utm_medium%3Daffiliate%26utm_campaign%3Duk_uk_en_affiliate_prospecting_prospecting_awin_all__1093747',
			'_blank'
		)
	const handleThrillzClick = () => window.open('http://shrsl.com/3e3za', '_blank')
	const handleAddonsClick = () => redirectToNextIfWindow(handlers.add_ons) // navigate(handlers.add_ons)

	/**
	 *  Fancy background
	 */
	const gradient = keyframes`
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
	`

	const animatedBackground = {
		background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
		// background: 'linear-gradient(120deg, #0066CC 62%, #06CB, #0066CC 63%) right/250% 100%', // Glare flash
		backgroundSize: '400% 100%',
		animation: `${gradient} 30s ease infinite`,
		willChange: 'background-position',
	}

	/**
	 * Build our items data and assign new onClick event
	 */
	const memmo = { ...memmoData, onClick: handleMemmoClick }
	const thrillz = { ...thrillzData, onClick: handleThrillzClick }
	const add_ons = {
		...addonsData,
		onClick: handleAddonsClick,
		btnProps: { color: 'white', colorScheme: 'whiteAlpha' },
		imgTransform: 'translate(-1.25rem, 0)',
	}

	return (
		<Box>
			<FeatureItemFullWidth
				border="none"
				sx={animatedBackground}
				color="white"
				item={add_ons}
				isFullyClickable
				overflow="hidden"
			/>
		</Box>
	)
}, areEqual)

Addons.displayName = 'Addons'

export default Addons
