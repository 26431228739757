import React from 'react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const CashCollectionSelector = ({ amount, onClick }) => {
	return (
		<NavCard onClick={onClick}>
			<NavCardCover path="/assets/images/graphics/navigation-cards/event-page-group-funds" />
			<NavCardContent title={'Gift Funds'} description={'Pool funds for a gift they’ll love'} />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}
